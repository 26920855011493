import { DocumentFileTypes, FileDropzone } from '@common/components/FileDropzone'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'
import { FileNamespace, useCreatePublicCopyMutation, useCreateUnsortedCopyMutation } from '@graphql/generated'
import { Button } from '@common/components/Button'
import { gql } from '@apollo/client'
import { BaseModal } from '@common/components/BaseModal'
import { useUploadedFiles } from '@common/features/files/use-uploaded-files'
import { useFileExplorer } from '@common/features/files/use-file-explorer'
import { SelectFileModal } from './SelectFileModal/SelectFileModal'

const SplitContainer = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`

gql`
  mutation CreatePublicCopy($key: String!) {
    fileCreatePublicCopy(key: $key) {
      error
      file {
        key
        url
      }
    }
  }
`

gql`
  mutation CreateUnsortedCopy($key: String!) {
    fileCreateUnsortedCopy(key: $key) {
      error
      file {
        key
        url
      }
    }
  }
`

export const SelectDocumentModal: React.FC<{
  isOpen: boolean
  onRequestClose: () => void
  onSelect: (url: string, displayName: string) => void
}> = ({ isOpen, onRequestClose, onSelect }) => {
  const [showArchive, setShowArchive] = useState(false)
  const theme = useTheme()
  const [fileUrl, setFileUrl] = useState<string | null>(null)

  const { uploadFiles } = useUploadedFiles(FileNamespace.Public)
  const { uploadedFiles } = useFileExplorer('', FileNamespace.General)

  const [createPublicCopy] = useCreatePublicCopyMutation()
  const [createUnsortedCopy] = useCreateUnsortedCopyMutation()

  const handleSelectDocument = () => {
    if (!fileUrl) return

    const displayName = fileUrl.split('/').pop() ?? ''

    onSelect(fileUrl, displayName)
    onRequestClose()
    setFileUrl(null)
  }

  useEffect(() => {
    if (!fileUrl) return

    handleSelectDocument()
  }, [fileUrl])

  return (
    <>
      <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div>
          <h2>Välj dokument</h2>
          <SplitContainer>
            <div>
              <Button variant="tertiary" style={{ width: '100%' }} onClick={() => setShowArchive(true)}>
                Välj från dokumentarkiv
              </Button>
            </div>
            <div>
              <div style={{ fontSize: '0.75rem', color: theme.onBackgroundSemiLight, textAlign: 'center' }}>eller</div>
            </div>
            <div>
              <FileDropzone
                label="Släpp en fil eller klicka här för att ladda upp"
                accept={DocumentFileTypes}
                onUpload={(acceptedFiles) => {
                  toast.promise(uploadFiles('', acceptedFiles), {
                    loading: 'Laddar upp filen',
                    success: (files) => {
                      setFileUrl(files?.[0]?.url ?? null)

                      const key = files?.[0]?.key
                      if (!key) return 'Filen uppladdad'

                      void createUnsortedCopy({ variables: { key } })

                      return 'Filen uppladdad'
                    },
                    error: 'Kunde inte ladda upp filen',
                  })
                }}
                height="15rem"
              />
            </div>
          </SplitContainer>
        </div>
      </BaseModal>
      <SelectFileModal
        onSelect={(key) =>
          toast.promise(createPublicCopy({ variables: { key } }), {
            loading: 'Laddar upp filen',
            success: (result) => {
              setFileUrl(result.data?.fileCreatePublicCopy?.file?.url ?? null)
              return 'Filen uppladdad'
            },
            error: 'Kunde inte ladda upp filen',
          })
        }
        onRequestClose={() => setShowArchive(false)}
        isOpen={showArchive}
        keys={uploadedFiles.map((file) => file.key)}
      />
    </>
  )
}
