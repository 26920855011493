import { useMemo } from 'react'
import { FileNamespace } from '@graphql/generated'
import { useSearchParams } from 'next/navigation'
import { useUploadedFiles } from './use-uploaded-files'

export const useFileExplorer = (filter: string, namespace: FileNamespace) => {
  const { filesGroupedByDirectories, uploadFiles, loading, refetch, uploadedFiles } = useUploadedFiles(namespace)

  const query = useSearchParams()

  const k = (query.get('k') ?? '').replaceAll('//', '/').replace(/^(\/)/, '')
  const d = (query.get('d') ?? '').replaceAll('//', '/').replace(/^(\/)/, '')

  const currentDir = filesGroupedByDirectories(d)
  const filteredFiles = useMemo(() => {
    const uploadedFiles = currentDir?.files ?? []

    if (!filter) return uploadedFiles

    return uploadedFiles.filter((file) => {
      if (file.displayName.toLowerCase().includes(filter.toLowerCase())) return true

      const uploadedAt = new Date(file.uploadedAt)
      const uploadedAtFormatted = uploadedAt.toLocaleString('sv-SE').split(':').slice(0, -1).join(':')

      if (uploadedAtFormatted.includes(filter.toLowerCase())) return true

      return false
    })
  }, [filter, currentDir])

  const filteredDirs = useMemo(() => {
    const directories = currentDir?.folders ?? []

    if (!filter) return directories

    return directories.filter((dir) => {
      if (dir.toLowerCase().includes(filter.toLowerCase())) return true

      return false
    })
  }, [currentDir?.folders, filter])

  return {
    files: filteredFiles.slice().sort((f1, f2) => (f1.uploadedAt > f2.uploadedAt ? -1 : 1)),
    folders: filteredDirs,
    key: k,
    directory: d,
    currentDirectory: currentDir,
    uploadFiles,
    uploadedFiles,
    loading,
    refetch,
  }
}
