import {
  FileNamespace,
  OrganizationFilesQuery,
  useCreateUploadUrlMutation,
  useOrganizationFilesLazyQuery,
  useOrganizationFilesQuery,
} from '@graphql/generated'
import { useState } from 'react'
import axios from 'axios'
import { gql } from '@apollo/client'

type UserFileOrDirectory = OrganizationFilesQuery['me']['organization']['files'][0]
export type UserFile = Omit<OrganizationFilesQuery['me']['organization']['files'][0], 'isDirectory'>

export const groupedByDirectories = (
  path: string,
  files: UserFileOrDirectory[],
): { folders: string[]; files: UserFile[] } => {
  const pathString = path.endsWith('/') || path === '' ? path : path + '/'

  const topLevelFiles = files.filter((file) => {
    if (file.key.endsWith('__dir') || !file.key.startsWith(pathString)) {
      return false
    }

    const isTopLevel = file.key.replace(pathString, '').split('/').length === 1

    return isTopLevel && !file.isDirectory
  })

  const folders = files.reduce<string[]>((acc, file) => {
    if (!file.key.endsWith('__dir') || !file.key.startsWith(pathString)) {
      return acc
    }

    const filenameParts = file.key.replace(pathString, '').split('/')
    const nextLevelFolder = filenameParts.length === 2

    if (nextLevelFolder) {
      const dirName = filenameParts[0]
      const dirExists = acc.includes(dirName)

      if (dirExists) {
        return acc
      }

      acc = [...acc, dirName]
      return acc
    }

    return acc
  }, [])

  return { folders, files: topLevelFiles }
}

gql`
  mutation CreateUploadUrl($path: String!, $name: String!, $contentType: String!, $namespace: FileNamespace!) {
    fileCreateUploadUrl(path: $path, name: $name, contentType: $contentType, namespace: $namespace) {
      filename
      url
    }
  }

  query OrganizationFiles($namespace: FileNamespace!) {
    me {
      organization {
        files(namespace: $namespace) {
          key
          displayName
          url
          size
          uploadedAt
          isDirectory
        }
      }
    }
  }
`

export const useUploadedFiles = (namespace: FileNamespace) => {
  const { data, refetch } = useOrganizationFilesQuery({ fetchPolicy: 'cache-and-network', variables: { namespace } })
  const [organizationFilesLazy] = useOrganizationFilesLazyQuery()
  const [error, setError] = useState<null | string>(null)
  const [loading, setLoading] = useState(false)
  const [createUrl] = useCreateUploadUrlMutation()

  const uploadedFiles  = data?.me?.organization?.files ?? []

  const handleCreateUrl = (path: string, name: string, contentType: string) =>
    createUrl({ variables: { path, name, contentType, namespace } })

  const handleUploadFiles = async (path: string, acceptedFiles: File[]) => {
    setError(null)
    setLoading(true)

    const existingFiles =
      (await organizationFilesLazy({ variables: { namespace } }))?.data?.me?.organization?.files?.map(
        ({ key }) => key,
      ) ?? []
    const overwrites = acceptedFiles.find((file) => existingFiles.includes(path + '/' + file.name))

    if (overwrites) {
      if (!window.confirm('En eller flera filer finns redan, vill du skriva över dessa?')) {
        return null
      }
    }

    const result = (
      await Promise.all(
        acceptedFiles.map(async (file) => {
          const result = (await handleCreateUrl(path, file.name, file.type))?.data?.fileCreateUploadUrl

          const url = result?.url
          const filename = result?.filename

          if (!url || !filename) {
            setLoading(false)
            setError(`Either filename or pre-signed URL is invalid (url=${url}, filename=${filename}`)
            return null
          }

          let aclHeader = 'private'
          if (namespace === FileNamespace.Public) {
            aclHeader = 'public-read'
          }

          if (namespace === FileNamespace.General) {
            aclHeader = 'private'
          }

          const response = await axios.put(url, file, {
            headers: { 'x-amz-acl': aclHeader, 'content-type': file.type },
          })

          if (response.status === 200) {
            setLoading(false)
            refetch()

            const publicUrl = url.split('?')[0]

            if (!publicUrl) {
              return null
            }

            return { file, url: publicUrl, key: file.name }
          } else {
            setError('Could not upload file, status was not 200')
          }

          setLoading(false)

          return null
        }),
      )
    ).filter((result) => !!result && !!result.file)

    return result
  }

  return {
    uploadedFiles,
    filesGroupedByDirectories: (path: string) => groupedByDirectories(path, uploadedFiles),
    uploadFiles: handleUploadFiles,
    loading,
    error,
    refetch,
  }
}
