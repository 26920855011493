import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IonIcon } from '@ionic/react'
import { arrowForwardSharp, arrowUpSharp, documentSharp, folderSharp } from 'ionicons/icons'

const Base = styled.div<{ isDirectory: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-width: 20rem;
  border-top: 1px solid ${({ theme }) => theme.onBackgroundLightest};

  padding: 1rem 0.5rem;

  ${({ isDirectory, theme }) =>
    !isDirectory &&
    css`
      :hover {
        color: ${theme.primary};
        background-color: ${theme.primaryLighter};
        cursor: pointer;
      }
    `}

  .right-icon {
    color: ${({ theme }) => theme.primaryLight};
    padding: 0 1rem 0 2rem;
    display: ${({ isDirectory }) => (isDirectory ? 'none' : 'block')};
  }

  .left-icon {
    color: ${({ theme, isDirectory }) => (isDirectory ? theme.onBackgroundSemiLight : theme.primaryLight)};
  }
`

const InnerContainer = styled.div<{ offset: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-left: ${({ offset }) => offset + 'rem'};
`

const Name = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface Entry {
  displayName: string
  key: string
  isDirectory: boolean
  children: Entry[]
}

export const Entry: React.FC<{
  top?: boolean
  offset: number
  displayName: string
  dirKey: string
  children: Entry[]
  isDirectory: boolean
  onSelect: (directory: string) => void
}> = ({ top, displayName, isDirectory, children, dirKey, onSelect, offset = 0 }) => {
  return (
    <>
      <Base isDirectory={isDirectory} onClick={() => !isDirectory && onSelect(dirKey)}>
        <InnerContainer offset={offset}>
          <IonIcon className="left-icon" icon={isDirectory ? folderSharp : documentSharp} />
          <div style={{ maxWidth: '40rem' }}>
            <Name>{displayName}</Name>
          </div>
        </InnerContainer>
        <IonIcon className="right-icon" icon={top ? arrowUpSharp : arrowForwardSharp} />
      </Base>
      {children.map((entry, i) => (
        <Entry
          isDirectory={entry.isDirectory}
          offset={offset + 1}
          key={displayName + entry.displayName + i}
          dirKey={entry.key}
          displayName={entry.displayName}
          onSelect={(key) => {
            onSelect(key)
          }}
        >
          {entry.children}
        </Entry>
      ))}
    </>
  )
}
