import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { IonIcon } from '@ionic/react'
import { imageSharp } from 'ionicons/icons'

export interface FileError {
  message: string
  code: ErrorCode | string
}

export interface FileRejection {
  file: File
  errors: FileError[]
}

const DropzoneContainer = styled.div<{ isDragActive: boolean }>`
  cursor: pointer;
  padding: 2rem;
  border: 1px solid ${({ theme, isDragActive }) => (isDragActive ? theme.primaryLight : theme.onBackgroundLighter)};

  text-align: center;

  p {
    color: ${({ theme, isDragActive }) => (isDragActive ? theme.primaryLight : theme.onBackgroundSemiLight)};
    font-size: 0.95rem;
  }

  .icon {
    color: ${({ theme, isDragActive }) => (isDragActive ? theme.primaryLight : theme.onBackgroundSemiLight)};
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.primaryLight};

    p {
      color: ${({ theme }) => theme.primaryLight};
    }
    .icon {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

interface Accept {
  [key: string]: string[]
}

export const DocumentFileTypes = {
  'application/zip': ['.zip'],
  'application/pdf': ['.pdf'],
  'text/csv': ['.csv'],
  'text/plain': ['.txt'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
}

export const ImageFileTypes = {
  'image/png': ['.png'],
  'image/webp': ['.webp'],
  'image/gif': ['.gif'],
  'image/jpeg': ['.jpg', '.jpeg'],
}

export const FileDropzone: React.FC<{
  onUpload: (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => void
  label?: string
  accept?: Accept
  maxFiles?: number
  height?: string
}> = ({ onUpload, label, accept, maxFiles, height }) => {
  const onDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
      onUpload(acceptedFiles, fileRejections)
    },
    [onUpload],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept ?? {
      ...ImageFileTypes,
      ...DocumentFileTypes,
    },
    maxFiles: maxFiles ?? 100,
    maxSize: 1024 * 1024 * 50, // 50Mb
  })

  return (
    <DropzoneContainer
      {...getRootProps()}
      isDragActive={isDragActive}
      style={{
        height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input {...getInputProps()} />
      <IonIcon className="icon" icon={imageSharp} />
      <p>{label ?? 'Släpp filer eller klicka här för att ladda upp'}</p>
    </DropzoneContainer>
  )
}
