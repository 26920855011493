import { useMemo } from 'react'

interface Entry {
  displayName: string
  key: string
  isDirectory: boolean
  children: Entry[]
}

function createNode(path: string[], tree: Entry[], history: string[]): void {
  const displayName = path.shift()
  const idx = tree.findIndex((d: Entry) => {
    return d.displayName === displayName
  })

  if (!displayName) {
    return
  }

  if (idx < 0) {
    const keyCandidate = history.join('/') + '/' + displayName
    const key = keyCandidate.startsWith('/') ? keyCandidate.substring(1) : keyCandidate

    if (path.length !== 0) {
      tree.push({
        displayName,
        children: [],
        isDirectory: true,
        key,
      })
      createNode(path, tree[tree.length - 1].children, [...history, displayName])
    } else {
      tree.push({
        displayName,
        children: [],
        isDirectory: false,
        key,
      })
    }
  } else {
    createNode(path, tree[idx].children, [...history, displayName])
  }
}

const parse = (data: string[]): Entry[] => {
  const tree: Entry[] = []

  data.forEach((_, i) => {
    const path = data[i]
    const split = path.split('/')

    createNode(split, tree, [])
  })

  return tree
}

export const useFileStructure = (keys: string[]): Entry[] => {
  return useMemo(() => parse(keys.filter((key) => !key.endsWith('__dir'))), [keys])
}
