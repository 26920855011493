import { Headline } from '@common/components/Headline'
import { Entry } from './components/Entry'
import { BaseModal } from '@common/components/BaseModal'
import styled from '@emotion/styled'
import { useFileStructure } from './hooks/use-file-structure'

interface SelectFileModalProps {
  onSelect: (file: string) => void
  onRequestClose: () => void
  isOpen: boolean
  keys: string[]
  placeholder?: string
}

const Placeholder = styled.div`
  color: ${({ theme }) => theme.onBackgroundSemiLight};

  min-width: 25rem;
  min-height: 8rem;

  margin-top: -2rem;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const SelectFileModal: React.FC<SelectFileModalProps> = ({
  onRequestClose,
  isOpen,
  onSelect,
  keys,
  placeholder,
}) => {
  const entries = useFileStructure(keys)

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Headline size="small" text="Välj fil" />
      <div style={{ marginTop: '2rem' }} />
      <div style={{ maxHeight: '60vh', maxWidth: '80vw', overflowY: 'scroll' }}>
        {!entries.length && <Placeholder>{placeholder ?? 'Inga filer hittades'}</Placeholder>}
        {entries.map((entry, i) => (
          <Entry
            offset={0}
            isDirectory={entry.isDirectory}
            key={entry.displayName + i}
            dirKey={entry.key}
            displayName={entry.displayName}
            onSelect={(dir) => {
              onSelect(dir)
              onRequestClose()
            }}
          >
            {entry.children}
          </Entry>
        ))}
      </div>
    </BaseModal>
  )
}
